import React, { useContext } from "react";
import classNames from "./navbar.module.scss";

//assets
import logoWithName from "../../assets/images/logowithnamewhite.svg";
import lockIcon from "../../assets/images/sidebar/icons/lock.svg";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../../context/Globalcontext";

const Navbar = () => {
  const history = useHistory();
  const { removeUserDetails } = useContext(GlobalContext);

  return (
    <div className={classNames.navbar}>
      <div className={classNames.leftContainer}>
        <img src={logoWithName} alt="logoWithName" />
        <input
          className={classNames.globalSearch}
          type="text"
          placeholder="Ask Luca..."
        />
      </div>
      <div className={classNames.rightContainer}>
        <div
          className={classNames.logout}
          onClick={() => {
            removeUserDetails();
            history.push("/login");
          }}
        >
          <img src={lockIcon} alt="lockIcon" />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
