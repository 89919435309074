import React, { useEffect, useRef, useState } from "react";
import classNames from "./custom.module.scss";
import "./custom.module.scss";

//assets
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import montageLogo from "../../assets/images/montage.svg";
import uploadIcon from "../../assets/images/icons/upload.svg";
import { areAllFieldsFilled, uploadFileFunc } from "../../assets/functions";

export const CustomTextInput = ({
  title,
  placeholder,
  type,
  name,
  stateValue,
  setState,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={classNames.customTextInput}>
      {title && <div className={classNames.title}>{title}</div>}
      <div className={classNames.inputContainer}>
        <input
          type={showPassword ? "text" : type ? type : "text"}
          value={name ? stateValue[name] : stateValue}
          placeholder={placeholder}
          onChange={(event) => {
            if (name) {
              setState((prev) => {
                return { ...prev, [name]: event.target.value };
              });
            } else {
              setState(event.target.value);
            }
          }}
        />
        {type === "password" && (
          <div
            className={classNames.showPasword}
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </div>
        )}
      </div>
    </div>
  );
};

export const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {});
  console.log("42ff", name);

  useEffect(() => {
    console.log(stateValue, "ljwekgfhkwjegfkjwef");
  }, [stateValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  useEffect(() => {
    if (
      name === "selectedAppStaff" &&
      Array?.isArray(dropdown) &&
      dropdown?.length > 0
    ) {
      setState({
        NameOfTeam: dropdown[0]?.NameOfTeam,
        Icon: dropdown[0]?.Icon,
        TeamUsername: dropdown[0]?.TeamUsername,
      });
    }
  }, [dropdown]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        // minWidth: isOpen ? "250px" : "",
        zIndex: isOpen ? "3" : "",
        height: topTitle ? "5.25rem" : "3rem",
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: topTitle ? "2rem" : "",
          maxHeight: isOpen ? "400px" : "100%",
          width: name == "peroid" && "120px",
          padding: name == "peroid" && "10px",
          borderBottom: name === "selectedAppStaff" && !isOpen ? "none" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {name === "selectedAppStaff" && stateValue?.Icon ? (
              <img
                src={stateValue?.Icon}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : name === "selectedAppStaff" ? (
              <img
                src={montageLogo}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : (
              name === "selectedApp" && (
                <img
                  src={montageLogo}
                  alt={stateValue?.NameOfTeam}
                  style={{ height: "1.25rem" }}
                />
              )
            )}

            {name === "locationName" ? (
              stateValue?.locationName == "" ? (
                "Select location"
              ) : (
                stateValue?.locationName
              )
            ) : name === "constultandName" ? (
              stateValue?.constultandName == "" ? (
                "Click to select"
              ) : (
                stateValue?.constultandName
              )
            ) : name === "location" ? (
              stateValue?.location == "" ? (
                "Select location"
              ) : (
                stateValue?.location
              )
            ) : name === "defaultDropdown" ? (
              stateValue == "" ? (
                "Click to select"
              ) : (
                stateValue
              )
            ) : title ===
              "Which staff member do you want to make a specialist?" ? (
              stateValue.spaceName == "" ? (
                "Click to select"
              ) : (
                stateValue.spaceName
              )
            ) : name === "specialistList" ? (
              !stateValue?.speicalistName ? (
                "Click to select"
              ) : (
                stateValue?.speicalistName
              )
            ) : name === "catfallunder" ? (
              !stateValue?.catfallunder ? (
                "Click to select"
              ) : (
                stateValue?.catfallunderName
              )
            ) : name === "brandsproduct" ? (
              !stateValue?.brandsproduct ? (
                "Click to select"
              ) : (
                stateValue?.brandsproductName
              )
            ) : name === "location" ? (
              !stateValue?.location ? (
                "Click to select"
              ) : (
                stateValue?.locationName
              )
            ) : name === "rooms" ? (
              !stateValue?.rooms ? (
                "Select room category"
              ) : (
                stateValue?.roomsName
              )
            ) : name === "theraphyCategoryId" ? (
              !stateValue?.theraphyCategoryId ? (
                "Click to select"
              ) : (
                stateValue?.theraphyCategoryName
              )
            ) : name === "peroid" ? (
              !stateValue?.peroid ? (
                "Click to select"
              ) : (
                stateValue?.peroid
              )
            ) : (
              <>
                {name === "selectedAppStaff" && stateValue?.NameOfTeam
                  ? stateValue?.NameOfTeam
                  : stateValue?.NameOfTeam && !name === "IndustriesId"
                  ? stateValue?.NameOfTeam
                  : selectedOption
                  ? selectedOption
                  : name === "IndustriesId"
                  ? "Click to select industries"
                  : title
                  ? title
                  : name === "selectedAppStaff"
                  ? "Select an Option"
                  : name === "catfallunder"
                  ? "Select an Option"
                  : dropdown[0]?.NameOfTeam}
              </>
            )}
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
              return;
            }}
          >
            {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {type === "twoFields" && dropdown?.length > 0 ? (
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      // let objj = {
                      //   ...eachValuee,
                      //   [calendarObjField]: eachitem,
                      // };
                      // console.log(objj, "objj checking");
                      event.stopPropagation();
                      selectOption(eachitem);
                      setState(() => {
                        const updatedAnnualHolidays = [...stateValue[name]];
                        updatedAnnualHolidays[indexx] = {
                          ...updatedAnnualHolidays[indexx],
                          [calendarObjField]: eachitem,
                        };

                        return {
                          ...stateValue,
                          [name]:
                            (calendarObjField === "date" ||
                              calendarObjField === "month") &&
                            areAllFieldsFilled(updatedAnnualHolidays[indexx])
                              ? [
                                  ...updatedAnnualHolidays,
                                  { month: "", date: "" },
                                ]
                              : updatedAnnualHolidays,
                        };
                      });
                    }}
                    key={eachitem + index}
                  >
                    {eachitem}
                  </li>
                );
              })
            ) : type === "obj" && dropdown?.length > 0 ? (
              <>
                <input
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e?.target?.value)}
                  style={{
                    border: "none",
                    width: "100%",
                    height: "2.3rem",
                    margin: "8px 0",
                    paddingLeft: "1rem",
                  }}
                />
                {dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row[mapVal?.name]
                      ?.toLowerCase()
                      ?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem[mapVal?.name]);
                          setState({
                            ...stateValue,
                            [name]: eachitem[stateVal],
                          });
                        }}
                        key={eachitem[mapVal?.name] + index}
                      >
                        {mapVal?.image && (
                          <img
                            src={eachitem[mapVal?.image]}
                            alt={eachitem[stateVal]}
                          />
                        )}
                        <div>
                          <div>{eachitem[mapVal?.name]}</div>
                          {mapVal?.desc && (
                            <div style={{ marginTop: "8px" }}>
                              {eachitem[mapVal?.desc]}
                            </div>
                          )}
                        </div>
                      </li>
                    );
                  })}
              </>
            ) : type === "objUserDetails" && dropdown?.length > 0 ? (
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(
                        eachitem?.UserDetails[0]?.UserDetails[0][mapVal?.name]
                      );
                      setState({
                        ...stateValue,
                        [name]:
                          eachitem?.UserDetails[0]?.UserDetails[0][stateVal],
                      });
                    }}
                    key={
                      eachitem?.UserDetails[0]?.UserDetails[0][mapVal?.name] +
                      index
                    }
                  >
                    {mapVal?.image && (
                      <img
                        src={eachitem[mapVal?.image]}
                        alt={eachitem[stateVal]}
                      />
                    )}
                    <div>
                      <div>
                        {eachitem?.UserDetails[0]?.UserDetails[0][mapVal?.name]}
                      </div>
                      {mapVal?.desc && (
                        <div style={{ marginTop: "8px" }}>
                          {eachitem[mapVal?.desc]}
                        </div>
                      )}
                    </div>
                  </li>
                );
              })
            ) : title === "All Countries" ? (
              dropdown?.length > 0 &&
              dropdown
                ?.filter((row) => {
                  // Convert the row object values to an array and check if any value matches the search query
                  const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                  return row?.toLowerCase()?.includes(searchQueryy);
                })
                ?.map((eachitem, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        selectOption(eachitem);
                        setState(eachitem);
                      }}
                      key={eachitem + index}
                      style={{
                        display: eachitem === stateValue ? "none" : "",
                      }}
                    >
                      {eachitem}
                    </li>
                  );
                })
            ) : (name === "Gender" ||
                name === "groupType" ||
                type === "single") &&
              dropdown?.length > 0 ? (
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(eachitem);
                      setState({
                        ...stateValue,
                        [name]: eachitem,
                      });
                    }}
                    key={eachitem + index}
                  >
                    {eachitem}
                  </li>
                );
              })
            ) : name === "IndustriesId" && dropdown?.length > 0 ? (
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(eachitem?.Name);
                      setState({
                        ...stateValue,
                        [name]: eachitem?._id,
                      });
                    }}
                    key={eachitem?.Name + index}
                  >
                    {eachitem?.Name}
                  </li>
                );
              })
            ) : name === "defaultDropdown" && dropdown?.length > 0 ? (
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(eachitem);
                      setState(eachitem);
                    }}
                    key={index}
                  >
                    {eachitem}
                  </li>
                );
              })
            ) : (
              dropdown?.length > 0 &&
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(eachitem?.NameOfTeam);
                      if (name === "teamUsername") {
                        setState((prev) => {
                          return {
                            ...prev,
                            [name]: eachitem?.TeamUsername,
                          };
                        });
                      } else if (name === "selectedAppStaff") {
                        setState({
                          NameOfTeam: eachitem?.NameOfTeam,
                          Icon: eachitem?.Icon,
                          TeamUsername: eachitem?.TeamUsername,
                        });
                      } else {
                        setState({
                          ...stateValue,
                          [name]: {
                            NameOfTeam: eachitem?.NameOfTeam,
                            Icon: eachitem?.Icon,
                          },
                        });
                      }
                    }}
                    key={eachitem?.NameOfTeam + index}
                  >
                    <img src={eachitem?.Icon} alt={eachitem?.NameOfTeam} />
                    {eachitem?.NameOfTeam}
                  </li>
                );
              })
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export const MyEachCustomImageUpload = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {
  //image update form
  const fileInputRefNewUser = useRef(null);
  const [uploadFileRFQ, setUploadFileRFQ] = useState("");
  const [uploadFileRFQName, setUploadFileRFQName] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);

  return (
    <div className={classNames.eachCustomImageUpload}>
      {topTitle && (
        <div
          className={classNames.topTitle}
          style={{
            fontWeight: "700",
          }}
        >
          {title}
        </div>
      )}
      <div className={classNames.imageInput}>
        {uploadFileRFQ ? (
          <img
            src={uploadFileRFQ}
            alt={uploadFileRFQName}
            onClick={() => {
              if (fileInputRefNewUser.current) {
                fileInputRefNewUser.current.click();
              }
            }}
          />
        ) : (
          <div
            className={classNames.emptyProfile}
            onClick={() => {
              if (fileInputRefNewUser.current) {
                fileInputRefNewUser.current.click();
              }
            }}
          >
            <img
              src={uploadIcon}
              alt="uploadIcon"
              style={{
                animation: updateLoading ? "heartbeat 1s infinite" : "",
              }}
            />
          </div>
        )}
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          style={{ display: "none" }}
          onChange={async (e) => {
            let resFile = uploadFileFunc(
              e,
              setUploadFileRFQName,
              setUpdateLoading,
              "name"
            );
            console.log(await resFile, "res file link await");
            setUploadFileRFQ(await resFile);
            let profileFile = await resFile;
            setState((prev) => {
              return {
                ...prev,
                [name]: profileFile,
              };
            });
          }}
          ref={fileInputRefNewUser}
        />
        <div
          className={classNames.imageLabel}
          style={{
            marginTop: "0.8rem",
            color: "rgba(0, 0, 0, 0.5)",
          }}
        >
          {placeholder}
        </div>
      </div>
    </div>
  );
};

export const EachCustomDropdownCountry = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  console.log("34555555555555", type);

  useEffect(() => {
    console.log(dropdown, "dropdowndropdowndropdowndropdown");
  }, [stateValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(true);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  useEffect(() => {
    setSearchQuery("");
    if (
      name === "selectedAppStaff" &&
      Array?.isArray(dropdown) &&
      dropdown?.length > 0
    ) {
      setState({
        NameOfTeam: dropdown[0]?.NameOfTeam,
        Icon: dropdown[0]?.Icon,
        TeamUsername: dropdown[0]?.TeamUsername,
      });
    }
  }, [dropdown]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        // minWidth: isOpen ? "250px" : "",
        zIndex: isOpen ? "3" : "",
        height: topTitle ? "5.25rem" : "3rem",
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: topTitle ? "2rem" : "",
          maxHeight: isOpen ? "400px" : "100%",
          borderBottom: name === "selectedAppStaff" && !isOpen ? "none" : "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {name === "selectedAppStaff" && stateValue?.Icon ? (
              <img
                src={stateValue?.Icon}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : name === "selectedAppStaff" ? (
              <img
                src={montageLogo}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : (
              name === "selectedApp" && (
                <img
                  src={montageLogo}
                  alt={stateValue?.NameOfTeam}
                  style={{ height: "1.25rem" }}
                />
              )
            )}

            {name === "locationName" ? (
              stateValue?.locationName == "" ? (
                "Click to select"
              ) : (
                stateValue?.locationName
              )
            ) : name === "constultandName" ? (
              stateValue?.constultandName == "" ? (
                "Click to select"
              ) : (
                stateValue?.constultandName
              )
            ) : title ===
              "Which staff member do you want to make a specialist?" ? (
              stateValue.spaceName == "" ? (
                "Click to select"
              ) : (
                stateValue.spaceName
              )
            ) : name === "specialistList" ? (
              !stateValue?.speicalistName ? (
                "Click to select"
              ) : (
                stateValue?.speicalistName
              )
            ) : (
              <>
                {name === "selectedAppStaff" && stateValue?.NameOfTeam
                  ? stateValue?.NameOfTeam
                  : stateValue?.NameOfTeam && !name === "IndustriesId"
                  ? stateValue?.NameOfTeam
                  : selectedOption
                  ? selectedOption
                  : name === "IndustriesId"
                  ? "Click to select industries"
                  : title
                  ? title
                  : name === "selectedAppStaff"
                  ? "Select an Option"
                  : dropdown[0]?.NameOfTeam}
              </>
            )}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {type === "obj" && dropdown?.length > 0 && (
              <>
                <input
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e?.target?.value)}
                  style={{
                    border: "none",
                    width: "100%",
                    height: "2.3rem",
                    margin: "8px 0",
                    paddingLeft: "1rem",
                  }}
                />
                {dropdown
                  ?.filter((temp) =>
                    temp?.name
                      ?.toLowerCase()
                      ?.includes(searchQuery?.toLowerCase())
                  )
                  ?.map((eachitem, index) => {
                    console.log("000", eachitem?.name);
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem[mapVal?.name]);
                          setState({
                            ...stateValue,
                            [name]: eachitem[stateVal],
                          });
                        }}
                        key={eachitem[mapVal?.name] + index}
                      >
                        {mapVal?.image && (
                          <img
                            src={eachitem[mapVal?.image]}
                            alt={eachitem[stateVal]}
                          />
                        )}

                        {eachitem[mapVal?.name]}
                      </li>
                    );
                  })}
              </>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};
