import React, { useState } from "react";
import classNames from "./sidebar.module.scss";
import { dashboardItems, miniSidebarItems } from "../../assets/data/mapItems";
import { Link, useLocation } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const Sidebar = () => {
  const location = useLocation();

  return (
    <div className={classNames.sidebar}>
      <div className={classNames.miniSidebar}>
        {miniSidebarItems?.map((eachItem, index) => {
          return (
            <>
              <ReactTooltip id={eachItem?.name} place="top" effect="solid">
                <span>{eachItem?.name}</span>
              </ReactTooltip>
              <Link
                className={classNames.eachItem}
                to={eachItem?.path}
                key={eachItem?.name + index}
                data-tooltip-id={eachItem?.name}
                style={{
                  opacity: !location?.pathname?.includes(eachItem?.mainPath)
                    ? "0.5"
                    : "",
                }}
              >
                <img src={eachItem?.icon} alt={eachItem?.name} />
                {/* {location?.pathname?.includes(eachItem?.mainPath) && (
                  <div className={classNames.title}>{eachItem?.name}</div>
                )} */}
              </Link>
            </>
          );
        })}
      </div>
      <div className={classNames.subSidebar}>
        {dashboardItems?.map((eachDashItem, index) => {
          return (
            <Link
              className={
                location?.pathname?.includes(eachDashItem?.path) &&
                classNames.selectedSub
              }
              to={eachDashItem?.path}
              key={eachDashItem?.name + index}
            >
              {eachDashItem?.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
