import React from "react";
import { Route, Switch } from "react-router-dom";
import Registration from "./pages/Registration";
import GlobalContextProvider from "./context/Globalcontext";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import Login from "./pages/Login";
import RegistrationNew from "./pages/NewRegistration";
import Dashboard from "./pages/Dashboard";
import DashboardLayout from "./Layout";
import Wallet from "./pages/Wallet";
import Support from "./pages/Support";
import { DashboardProfile } from "./pages/Dashboard/Profile";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
      },
    },
  });

  const allLoggedInPaths = [
    { path: "/dashboard/profile", component: <DashboardProfile /> },
    { path: "/dashboard/kyc", component: <div></div> },
    { path: "/dashboard/apps", component: <div></div> },
    { path: "/dashboard/settings", component: <div></div> },
    { path: "/wallet", component: <Wallet /> },
    { path: "/support", component: <Support /> },
  ];

  return (
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <GlobalContextProvider>
          <BrowserRouter>
            <Switch>
              <Route path="/" component={Login} exact />
              <Route path="/login" component={Login} />
              <Route path="/registration" component={RegistrationNew} />
              <Route path="/registrationold" component={Registration} />
              {allLoggedInPaths?.map((eachPath, index) => {
                return (
                  <Route
                    path={eachPath?.path}
                    component={() => (
                      <DashboardLayout child={eachPath?.component} />
                    )}
                  />
                );
              })}
              <Route path="*" component={Registration} />
            </Switch>
            <ToastContainer />
          </BrowserRouter>
        </GlobalContextProvider>
      </React.StrictMode>
    </QueryClientProvider>
  );
}

export default App;
