import React from "react";
import classNames from "./layout.module.scss";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

const DashboardLayout = ({ child }) => {
  return (
    <div className={classNames.dashboardLayout}>
      <Navbar />
      <div className={classNames.contentContainer}>
        <Sidebar />
        <div className={classNames.content}>{child}</div>
      </div>
    </div>
  );
};

export default DashboardLayout;
