import Cookies from "js-cookie";
import React, { createContext, useState } from "react";
import { APP_USER_TOKEN } from "../assets/data/constant";

export const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const [globalLoading, setGlobalLoading] = useState(false);

  //credentials
  const [email, setEmail] = useState(() => {
    const storedEmail = localStorage.getItem("userEmail");
    return storedEmail ? storedEmail : "";
  });
  const [accessToken, setAccessToken] = useState(() => {
    const storedToken = localStorage.getItem("userAccessToken");
    return storedToken ? storedToken : "";
  });
  const [loggedInUser, setLoggedInUser] = useState(() => {
    const storedUser = localStorage.getItem("loggedInUser");
    return storedUser ? JSON.parse(storedUser) : {};
  });

  //functions

  function removeUserDetails() {
    setAccessToken("");
    setLoggedInUser("");
    localStorage.removeItem("userAccessToken");
    localStorage.removeItem("loggedInUser");
  }

  return (
    <GlobalContext.Provider
      value={{
        globalLoading,
        setGlobalLoading,
        //credentials
        email,
        setEmail,
        accessToken,
        setAccessToken,
        loggedInUser,
        setLoggedInUser,
        //functions
        removeUserDetails,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
