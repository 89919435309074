import React, { useContext, useState } from "react";
import classNames from "./login.module.scss";

//assets
import logoWithName from "../../assets/images/logowithname.svg";
import { CustomTextInput } from "../../components/Custom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { toast } from "react-toastify";
import { GlobalContext } from "../../context/Globalcontext";

const Login = () => {
  const history = useHistory();
  const { setLoggedInUser, setAccessToken, setEmail, removeUserDetails } =
    useContext(GlobalContext);
  const [loginDetails, setLoginDetails] = useState({});
  const [localLoading, setLocalLoading] = useState(false);

  //functions

  async function loginUser() {
    setLocalLoading(true);
    try {
      let response = await axios.post(
        "https://newauth.apimachine.com/v1/user/login",
        {
          appCode: "finace",
          email: loginDetails?.email,
          password: loginDetails?.password,
        }
      );
      setLocalLoading(false);
      if (response?.data?.status && response?.data?.data?.token) {
        toast.success(response?.data?.data?.message);
        setAccessToken(response?.data?.data?.token);
        setLoggedInUser(response?.data?.data);
        setEmail(loginDetails?.email);
        localStorage.setItem("userAccessToken", response?.data?.data?.token);
        localStorage.setItem("userEmail", loginDetails?.email);
        localStorage.setItem(
          "loggedInUser",
          JSON.stringify(response?.data?.data)
        );
        history.push("/dashboard/profile");
      } else {
        removeUserDetails();
        toast.error(response?.data?.data?.message);
      }
      console.log(response, "Login response");
    } catch (error) {
      removeUserDetails();
      toast.error("Invalid user credentials!");
      setLocalLoading(false);
      console.log(error?.message, "Login user error");
    }
  }

  return (
    <div className={classNames.mainLoginContainer}>
      <div className={classNames.leftContainer}>
        <div className={classNames.topLogo}>
          <img
            src={logoWithName}
            alt="logoWithName"
            onClick={() => history.push("/")}
          />
        </div>
        <div className={classNames.loginContainer}>
          <div className={classNames.inputsContainer}>
            <div className={classNames.header}>
              <div className={classNames.title}>Welcome Back</div>
              <div className={classNames.titledesc}>
                Please Enter Your Details
              </div>
            </div>
            <div className={classNames.inputs}>
              <CustomTextInput
                placeholder="Email"
                name="email"
                stateValue={loginDetails}
                setState={setLoginDetails}
              />
              <CustomTextInput
                type="password"
                placeholder="Password"
                name="password"
                stateValue={loginDetails}
                setState={setLoginDetails}
              />
              <div className={classNames.forgetPasswordBtn}>
                Forgot Password
              </div>
            </div>
            <div
              className={`${classNames.loginBtn} ${
                localLoading && classNames.notAllowed
              }`}
              onClick={loginUser}
            >
              {localLoading ? "Loading..." : "Login"}
            </div>
            <div className={classNames.signupBtn}>
              Don’t have an account?{" "}
              <span onClick={() => history.push("/registration")}>Signup</span>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.rightContainer}></div>
    </div>
  );
};

export default Login;
