export const APP_CODE = "finace";

export const APP_USER_TOKEN = "app_user_token";

export const COUNTRY = "India";

export const allNav = [
  { name: "Finaceverse", path: "/dashboard/profile/finaceverse" },
  {
    name: "Accountants.io",
    subMenu: [{ name: "Client" }, { name: "Accountant" }],
  },
  {
    name: "Accute",
    subMenu: [{ name: "Client" }, { name: "Employee" }, { name: "Firm owner" }],
  },
  { name: "Finaid" },
  { name: "Luca" },
  { name: "MyCabinets" },
];
